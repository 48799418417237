<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <tabset>
      <tab (selectTab)="tabChange('new')" heading="Create Task">
        <form [formGroup]="form">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('component')?.dirty"
                for="component"
              >
                {{ "headers.tasks_parameters.component.component" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="component"
                id="component"
                type="text"
              />
            </div>

            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('description')?.dirty"
                for="description"
              >
                {{ "headers.fastpm.pm_model.description" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="description"
                id="description"
                type="text"
              />
            </div>
            <div class="form-group col-8">
              <label
                [class.font-weight-bold]="form.get('criteria')?.dirty"
                for="criteria"
              >
                {{ "headers.fastpm.pm_model.criteria" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="criteria"
                id="criteria"
                type="text"
              />
            </div>

            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('activity')?.dirty"
                for="activity"
              >
                {{ "headers.tasks_parameters.activity.activity" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="activityReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'activity'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="activityReference.length === 0"
                [items]="activityReference"
                [placeholder]="
                  activityReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="activity"
                id="activity"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('trade')?.dirty"
                for="trade"
              >
                {{ "headers.tasks_parameters.labor.labor" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="tradeReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'trade'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="tradeReference.length === 0"
                [items]="tradeReference"
                [placeholder]="
                  tradeReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="trade"
                id="trade"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('frequency')?.dirty"
                for="frequency"
              >
                {{ "headers.tasks_parameters.frequency.frequency" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="frequencyReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'frequency'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="frequencyReference.length === 0"
                [items]="frequencyReference"
                [placeholder]="
                  frequencyReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="frequency"
                id="frequency"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('state')?.dirty"
                for="state"
              >
                {{ "headers.tasks_parameters.state.state" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="stateReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'state'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="stateReference.length === 0"
                [items]="stateReference"
                [placeholder]="
                  stateReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="state"
                id="state"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('duration')?.dirty"
                for="duration"
              >
                {{ "headers.fastpm.pm_model.duration" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="duration"
                id="duration"
                type="number"
              />
            </div>
            <div class="form-group col-8">
              <label
                [class.font-weight-bold]="form.get('tools')?.dirty"
                for="tools"
              >
                {{ "headers.tasks_parameters.tool.tool" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="toolingReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'tools'"
              ></app-field-clear-button>
              <ngx-select
                [autoClearSearch]="true"
                [disabled]="toolingReference.length === 0"
                [items]="toolingReference"
                [multiple]="true"
                [placeholder]="
                  toolingReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="tools"
                id="tools"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('keyword')?.dirty"
                for="keyword"
              >
                {{ "headers.fastpm.pm_model.keyword" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="keyword"
                id="keyword"
                type="text"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('procedure')?.dirty"
                for="procedure"
              >
                {{ "headers.fastpm.pm_model.proc" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="procedure"
                id="procedure"
                type="text"
              />
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('justification')?.dirty"
                for="justification"
              >
                {{ "headers.fastpm.pm_model.justification" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="justification"
                id="justification"
                type="text"
              />
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('comment')?.dirty"
                for="comment"
              >
                {{ "headers.fastpm.pm_model.comment" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="comment"
                id="comment"
                type="text"
              />
            </div>
          </div>
        </form>
      </tab>
      <tab
        (selectTab)="tabChange('model')"
        heading="{{ 'navigations.model.from' | translate }}"
        style="height: 450px"
      >
        <div class="d-flex flex-column h-100 w-100">
          <div class="d-flex align-items-center my-2">
            <div class="mr-2">
              <button
                (click)="modelTasksLoadItems(this.currentSite)"
                [ngClass]="{
                  'btn-success': modelTasksIsLoading,
                  'btn-light': !modelTasksIsLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': modelTasksIsLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="modelTasksGrid?.expandAll()"
                [disabled]="modelTasksIsLoading"
                class="btn btn-light btn-sm"
              >
                <i class="la la-expand-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="modelTasksGrid?.collapseAll()"
                [disabled]="modelTasksIsLoading"
                class="btn btn-light btn-sm"
              >
                <i class="la la-compress-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <div class="btn-group mr-1" dropdown>
                <button
                  class="dropdown-toggle btn btn-sm btn-primary"
                  dropdownToggle
                  type="button"
                >
                  {{ currentSite.name }}
                </button>
                <ul *dropdownMenu class="dropdown-menu">
                  <li
                    (click)="setSite(site)"
                    *ngFor="let site of sitesReferences"
                    class="btn btn-sm dropdown-item"
                  >
                    {{ site.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="d-flex h-100 w-100 flex-grow-1 ag-grid-container">
            <app-grid
              [gridUid]="'fpm_models_tasks_from_models'"
              (selectionChanged)="modelTasksOnSelectionChanged($event)"
              [autoGroupColumnDef]="modelTasksGridAutoGroupColumnDef"
              [gridOptions]="modelTasksGridOptions"
              [rowData]="modelTasks"
              class="ag-theme-alpine"
              style="width: 100%; height: 100%"
            >
            </app-grid>
          </div>
        </div>
      </tab>
      <tab
        (selectTab)="tabChange('component')"
        heading="{{ 'navigations.from_library' | translate }}"
        style="height: 450px"
      >
        <div class="d-flex flex-column h-100 w-100">
          <div class="d-flex align-items-center my-2">
            <div class="mr-2">
              <button
                (click)="componentTasksLoadItems()"
                [ngClass]="{
                  'btn-success': componentTasksIsLoading,
                  'btn-light': !componentTasksIsLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': componentTasksIsLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="componentTasksGrid?.expandAll()"
                [disabled]="componentTasksIsLoading"
                class="btn btn-light btn-sm"
              >
                <i class="la la-expand-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="componentTasksGrid?.collapseAll()"
                [disabled]="componentTasksIsLoading"
                class="btn btn-light btn-sm"
              >
                <i class="la la-compress-arrows-alt"></i>
              </button>
            </div>
          </div>
          <div class="d-flex h-100 w-100 flex-grow-1">
            <app-grid
              [gridUid]="'fpm_models_tasks_from_components'"
              (selectionChanged)="componentTasksOnSelectionChanged($event)"
              [autoGroupColumnDef]="componentTasksGridAutoGroupColumnDef"
              [gridOptions]="componentTasksGridOptions"
              [rowData]="componentTasks"
              class="ag-theme-alpine"
              style="width: 100%; height: 100%"
            >
            </app-grid>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) || !form.valid
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
