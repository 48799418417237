import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-field-clear-button',
  templateUrl: './field-clear-button.component.html',
  styleUrls: ['./field-clear-button.component.scss'],
})
export class FieldClearButtonComponent {
  // Input parameters
  @Input() formGroupRef: any = null;
  @Input() fieldName: any = null;
  @Input() value?: any = null;
  @Output() setEmptyParent = new EventEmitter<any>();

  /**
   * @param translate
   */
  constructor(public translate: TranslateService) {}

  /**
   * Empty a field and mark it as dirty
   */
  setEmpty(): void {
    if (this.setEmptyParent.observers.length > 0) {
      this.setEmptyParent.emit();
      return;
    }

    const arrayValueFields = ['tools', 'criticalities', 'accesses'];

    if (arrayValueFields.indexOf(this.fieldName) >= 0) {
      this.formGroupRef.get(this.fieldName)?.setValue([]);
    } else if (this.value === '') {
      this.formGroupRef.get(this.fieldName)?.setValue(this.value);
    } else {
      this.formGroupRef.get(this.fieldName)?.setValue(null);
    }
    this.formGroupRef.get(this.fieldName)?.markAsDirty();
  }
}
