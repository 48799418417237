import {
  AfterContentChecked,
  Component,
  OnInit,
  ViewChildren,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
  SharedDataService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GridComponent } from '../../shared';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { AssetSummaryComponent } from '../../shared/components/asset-summary/asset-summary.component';
import { ActivatedRoute } from '@angular/router';
import { ModelTasksPreviewComponent } from 'src/app/shared/components/model-tasks/model-tasks-preview.component';
import { AssetMatchComponent } from '../assets/match/match.component';
import { copyToClipboard } from 'src/utils';

@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.scss'],
})
export class StrategyListComponent implements OnInit, AfterContentChecked {
  @ViewChildren(GridComponent) grids?: GridComponent;

  assetsGrid?: any;
  strategiesGrid?: any;
  modelsGrid?: any;
  isDefaultAssetSelected = false;
  isAssetModelsUpdated = false;

  // Available items
  filteredItems: any[] = [];
  assets: any[] = [];
  strategies: any[] = [];
  models: any[] = [];
  assetsSelectedNodes: any[] = [];
  strategiesSelectedNodes: any[] = [];
  modelsSelectedNodes: any[] = [];
  assetFilterFlag = 0;
  // UI flag
  assetsIsLoading = false;
  strategiesIsLoading = false;
  modelsIsLoading = false;
  // Local grid options
  assetsGridOptions?: GridOptions;
  strategiesGridOptions?: GridOptions;
  modelsGridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  customHeaders?: any;
  displayTree = true;
  assetGridAutoGroupColumnDef: any;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    protected sharedData: SharedDataService,
    public breadCrumbService: BreadCrumbService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngAfterContentChecked(): void {
    // @ts-ignore
    this.assetsGrid = this.grids?._results[0];
    // @ts-ignore
    this.strategiesGrid = this.grids?._results[1];
    // @ts-ignore
    this.modelsGrid = this.grids?._results[2];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.pm_strategy'),
          link: '/strategy',
        },
      ],
      2
    );
    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );
    this.assetsGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      treeData: true,
      groupDefaultExpanded: -1, // expand all groups by default
      getDataPath: (data) => {
        if (this.displayTree) {
          return data.hierarchy;
        } else {
          return data.hierarchy.slice(-1);
        }
      },
      excludeChildrenWhenTreeDataFiltering: true,
      onRowDataChanged: (event) => this.filterAndSelectDefaultAsset(event),
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.description'),
          field: 'description',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant('headers.asset.type'),
          field: 'type',
        },
        {
          headerName: this.translate.instant('headers.asset.active'),
          field: 'active',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.ranking'),
          field: 'ranking',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.system'),
          field: 'system',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.parent'),
          field: 'parent',
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location',
        },
        {
          headerName: this.translate.instant('headers.asset.parentId'),
          field: 'parentId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'model_ids',
          valueGetter: (params: any): any => {
            if (params.data) {
              return this.filterAssetsModelList(params.data.model_ids);
            }
          },
          filterValueGetter: (params: any): any => {
            if (params.data) {
              return this.filterAssetsModelList(params.data.model_ids);
            }
          },
          cellRenderer: (params: any) => {
            return this.filterAssetsModelList(params.data.model_ids).join(', ');
          },
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'modules.admin.asset_parameters.children.access_requirements.name'
          ),
          field: 'accesses',
          valueGetter: (params: any): any[] => {
            if (params.data !== undefined && params.data.accesses.length > 0) {
              return _.map(params.data.accesses, (el) => el.description);
            }
            // for (Blanks) option
            return [''];
          },
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.drawing'),
          field: 'drawing',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqserial'),
          field: 'eqserial',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqsupplier'),
          field: 'eqsupplier',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_1'
          ),
          field: 'unit_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_2'
          ),
          field: 'planningGroup_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_3'
          ),
          field: 'costCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_4'
          ),
          field: 'workCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_risk_amount'
          ),
          field: 'base_risk_amount',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_criticality'
          ),
          field: 'base_crit_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_risk_amount'
          ),
          field: 'actual_risk_amount',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_criticality'
          ),
          field: 'actual_crit_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.asset_criticality'
          ),
          field: 'criticality',
          hide: true,
        },
      ],
    };
    this.strategiesGridOptions = {
      getRowId: (row: any) => row.data.assetId + '-' + row.data.modelId,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
          cellRenderer: (params: any) => {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showSummaryModal(params.data.assetId);
            };
            aTag.innerHTML = params.data.asset;
            return aTag;
          },
        },
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'assetId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'model',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'modelId',
          hide: true,
        },
      ],
    };
    this.modelsGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
          cellRenderer: (params: any) => {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showModelTaskPreview(params.data.id);
            };
            aTag.innerHTML = params.data.name;
            return aTag;
          },
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'id',
          hide: true,
        },
      ],
    };

    this.assetGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.asset.asset'),
      filter: 'agTextColumnFilter',
      valueGetter: (params: any) => {
        if (params.data !== undefined) {
          return params.data.name;
        }
      },
      cellRendererParams: {
        checkbox: (param: any) => !param?.data?.system,
        suppressDoubleClickExpand: true,
        innerRenderer: (params: any): any => {
          if (params.data) {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showSummaryModal(params.data.id);
            };
            aTag.innerHTML = params.data.name;
            return aTag;
          }
        },
      },
      sortable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    await this.assetsLoadItems();
    await this.strategiesLoadItems();
    await this.modelsLoadItems();
  }

  setDisplayTree(flag: boolean): void {
    if (flag !== this.displayTree) {
      this.displayTree = flag;
      this.assetsGrid?.gridApi?.refreshClientSideRowModel();
    }
    this.setFilteredItemsForFlatList();
  }

  private setFilteredItemsForFlatList(): void {
    if (!this.displayTree) {
      this.filteredItems = this.assets.filter((item) => !item?.system);
    }
  }

  filterAssetsModelList(modelIds: string): any[] {
    if (modelIds) {
      const modelIdsArr = modelIds.split(',').map(Number);
      const models = this.models.filter((item: any) =>
        modelIdsArr.includes(item.id)
      );
      return models.map((x) => x.name);
    }
    return [];
  }

  /**
   * LOADERS
   */
  async assetsLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.assetsIsLoading = true;
    }
    this.assets = await this.data
      .post('api/assets/from_site_all', {
        criteria: {
          active: 1,
        },
      })
      .toPromise();
    this.setFilteredItemsForFlatList();
    this.assetFilterFlag = 0;
    this.isAssetModelsUpdated = false;
    this.assetsGrid?.uncheckAll();
    if (!silent) {
      this.assetsIsLoading = false;
    }
  }

  async refreshGrid(): Promise<void> {
    await this.assetsLoadItems();
    setTimeout(() => {
      this.assetsGridOptions?.api?.redrawRows();
    });
  }

  async strategiesLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.strategiesIsLoading = true;
    }
    this.strategies = await this.data
      .get('api/assets/strategies_from_site')
      .toPromise();
    this.strategiesGrid?.uncheckAll();
    if (!silent) {
      this.strategiesIsLoading = false;
    }
  }

  async modelsLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.modelsIsLoading = true;
    }
    this.models = await this.data
      .post('api/fastpm/models/by', {
        criteria: {
          site: [this.auth.getSiteId(), null],
        },
      })
      .toPromise();
    this.modelsGrid?.uncheckAll();
    if (!silent) {
      this.modelsIsLoading = false;
    }
  }

  /**
   * ASSETS
   */
  assetsOnFilterChanged(event: any): void {}

  getAllRowsField(field: string): [] {
    const rowData: any = [];
    this.assetsGridOptions?.api?.forEachNode((node) =>
      rowData.push(node.data[field])
    );
    return rowData;
  }

  async showMatchAssetModal(): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetMatchComponent, {
      initialState: {
        assetNames: this.getAllRowsField('name'),
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: any) => {
      // select assets in grid
      this.assetsGrid?.uncheckAll();
      this.assetsGridOptions?.api?.forEachNode((node) => {
        if (res.includes(node.data.name.toLowerCase())) {
          node.setSelected(true);
        }
      });
    };
  }

  getFilteredNodes(): any {
    const rowData: any[] = [];
    this.assetsGrid?.gridApi?.forEachNodeAfterFilter((node: any) => {
      rowData.push(node.data);
    });
    return rowData;
  }

  copyToClipboard(): void {
    copyToClipboard(this.assetsSelectedNodes);
  }

  assetsOnSelectionChanged(event: any): void {
    this.assetsSelectedNodes = event.filter((node: any) => !node.data.system);

    if (this.assetFilterFlag === 1 && this.assetsSelectedNodes.length === 0) {
      this.filterStrategiesBy(
        'assetId',
        _.map(this.getFilteredNodes(), (el) => el.id + '')
      );
    } else {
      this.filterStrategiesBy(
        'assetId',
        _.map(this.assetsSelectedNodes, (el) => el.id + '')
      );
    }
  }

  filterAssetsByModelCount(flag: number): void {
    this.assetFilterFlag = flag;
    let ids: string[] = [];
    if (flag === 0) {
      ids = [];
    } else if (flag === 1) {
      ids = this.assets
        .filter((el) => el.modelCount === 0)
        .map((el) => el.id + '');
    } else if (flag === 2) {
      ids = this.assets
        .filter((el) => el.modelCount > 0)
        .map((el) => el.id + '');
    }
    this.filterAssetsBy('id', ids);
    this.filterStrategiesBy('assetId', ids);
  }

  filterAssetsBy(key: string, ids: string[]): void {
    const instance = this.assetsGrid?.gridApi?.getFilterInstance(key);
    if (ids.length > 0) {
      instance?.setModel({ values: ids });
    } else {
      instance?.setModel(null);
    }
    this.assetsGrid?.gridApi?.onFilterChanged();
  }

  /**
   * STRATEGIES
   */
  strategiesOnFilterChanged(event: any): void {}

  strategiesOnSelectionChanged(event: any): void {
    this.strategiesSelectedNodes = event;
  }

  strategiesRowChangedCallBack = () => {
    this.filterStrategiesBy(
      'assetId',
      _.map(this.assetsSelectedNodes, (el) => el.id + '')
    );
    this.filterStrategiesBy(
      'modelId',
      _.map(this.modelsSelectedNodes, (el) => el.id + '')
    );
  };

  filterStrategiesBy(key: string, ids: string[]): void {
    const instance = this.strategiesGrid?.gridApi?.getFilterInstance(key);
    if (ids.length > 0) {
      instance?.setModel({ values: ids });
    } else {
      instance?.setModel(null);
    }
    this.strategiesGrid?.gridApi?.onFilterChanged();
  }

  addStrategies(): void {
    const strategies: { asset: number; model: number }[] = [];
    _.each(this.assetsSelectedNodes, (asset) => {
      _.each(this.modelsSelectedNodes, (model) => {
        strategies.push({
          asset: asset.data.id,
          model: model.data.id,
        });
      });
    });
    if (strategies.length > 0) {
      this.data.post('api/assets/strategies_add', strategies).subscribe(
        (res) => {
          this.strategiesLoadItems();
          this.isAssetModelsUpdated = true;
          this.toastr.showSuccess();
        },
        (err) => {
          this.toastr.showError();
        }
      );
    }
  }

  removeStrategies(): void {
    const strategies: { asset: number; model: number }[] = [];
    _.each(this.strategiesSelectedNodes, (el) => {
      strategies.push({
        asset: el.data.assetId,
        model: el.data.modelId,
      });
    });
    if (strategies.length > 0) {
      this.data.post('api/assets/strategies_remove', strategies).subscribe(
        (res) => {
          this.strategiesLoadItems();
          this.isAssetModelsUpdated = true;
          this.toastr.showSuccess();
        },
        (err) => {
          this.toastr.showError();
        }
      );
    }
  }

  /**
   * MODELS
   */
  modelsOnFilterChanged(event: any): void {}

  modelsOnSelectionChanged(event: any): void {
    this.modelsSelectedNodes = event;
    this.filterStrategiesBy(
      'modelId',
      _.map(this.modelsSelectedNodes, (el) => el.id + '')
    );
  }

  async showSummaryModal(assetId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  async showModelTaskPreview(modelId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(ModelTasksPreviewComponent, {
      initialState: {
        modelId,
      },
      class: 'modal-lg',
    });
  }

  filterAndSelectDefaultAsset = (event: any): void => {
    const assetId = this.activatedRoute.snapshot.queryParamMap.get('asset_id');

    if (
      !assetId ||
      this.isDefaultAssetSelected ||
      event.api.getDisplayedRowCount() === 0
    ) {
      return;
    }

    let isAssetAvailable = false;

    event.api.forEachNode((node: any) => {
      if (node.data.id === +assetId) {
        isAssetAvailable = true;

        const hardcodedFilter = {
          name: {
            type: 'set',
            filter: node.data.name,
          },
        };
        event.api.setFilterModel(hardcodedFilter);
        node.setSelected(true);

        if (!this.isDefaultAssetSelected) {
          this.isDefaultAssetSelected = true;
        }
      }
    });

    if (!isAssetAvailable) {
      this.toastr.showError(this.translate.instant('messages.asset.not_found'));
    }
  };
}
